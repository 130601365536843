<template>
  <div>
    <b-card no-body class="shadow-sm">
      <template v-slot:header>
        <header>
          Dashboard Mahasiswa - Selamat Datang
          <strong>{{ nama }}</strong>
        </header>
      </template>

    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DashboardMahasiswa',
  computed: {
    ...mapGetters({
      userProfile: 'users/getUserProfile'
    }),
    nama () {
      return this.userProfile.mhsNama
    }
  }
}
</script>
