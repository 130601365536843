<template>
  <component :is="dashboard" />
</template>

<script>
import { mapGetters } from 'vuex'
import DashboardDosen from './dashboard/Dosen'
import DashboardInternal from './dashboard/Internal'
import DashboardMahasiswa from './dashboard/Mahasiswa'

export default {
  name: 'Dashboard',
  computed: {
    ...mapGetters('users', {
      userDetail: 'getUserDetail'
    }),
    dashboard () {
      let tempDashboard = null

      switch (this.userDetail.role) {
        case 'dosen':
          tempDashboard = DashboardDosen
          break
        case 'mahasiswa':
          tempDashboard = DashboardMahasiswa
          break
        case 'internal':
          tempDashboard = DashboardInternal
          break
      }

      return tempDashboard
    }
  }
}
</script>
