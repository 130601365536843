<template>
  <div>
    <p class="lead font-weight-bold">Rekapitulasi Input Nilai Mahasiswa (Per Matakuliah)</p>

    <b-card-group class="mb-4" deck>
      <b-card border-variant="warning" class="shadow bg-noprogress d-inline-flex justify-content-between">
        <b-card-text class="text-white d-flex justify-content-between align-items-center" text-tag="div">
          <h5 class="font-weight-bold">Belum Lengkap</h5>
          <b-skeleton v-if="badgeLoading" type="input" variant="warning"></b-skeleton>
          <h3 v-else><b-badge variant="light">{{ noprogress }}</b-badge></h3>
        </b-card-text>
      </b-card>
      <b-card border-variant="primary" class="shadow bg-inprogress d-inline-flex justify-content-between">
        <b-card-text class="text-white d-flex justify-content-between align-items-center" text-tag="div">
          <h5 class="font-weight-bold">Dalam Proses</h5>
          <b-skeleton v-if="badgeLoading" type="input" variant="primary"></b-skeleton>
          <h3 v-else><b-badge variant="light">{{ inprogress }}</b-badge></h3>
        </b-card-text>
      </b-card>
      <b-card border-variant="success" class="shadow bg-complete d-inline-flex justify-content-between">
        <b-card-text class="text-white d-flex justify-content-between align-items-center" text-tag="div">
          <h5 class="font-weight-bold">Lengkap</h5>
          <b-skeleton v-if="badgeLoading" type="input" variant="success"></b-skeleton>
          <h3 v-else><b-badge variant="light">{{ complete }}</b-badge></h3>
        </b-card-text>
      </b-card>
    </b-card-group>

    <c-table
      :table-data="nilai"
      :fields="captions"
      primary-key="klsId"
      :loading="loadingNilai"
    >
      <template v-slot:cell(dsnkIsBolehInputNilaiOnline)="{ item }">
        <TitleIconButton
          class="btn-sm"
          :variant="bagdeGradeStatus(item.dsnkIsBolehInputNilaiOnline)"
          :caption="isBolehInputCaption(item)"
          tooltip-text="Status Input Nilai"
        >
          <template v-slot:icon>
            <component :is="bagdeGradeIcon(item.dsnkIsBolehInputNilaiOnline)"></component>
          </template>
        </TitleIconButton>
      </template>
      <template v-slot:cell(percentage)="{ item }">
        <b-progress height="1.5rem" :max="valueNotExists(item) === true ? 1 : total(item)">
          <b-progress-bar
            :variant="completeBarVariant(item)"
            :value="item.jmlNilaiLengkap"
            :animated="animateBar(item)"
            :striped="stripedBar(item)"
            show-value
          />
          <b-progress-bar
            :value="valueNotExists(item) === true ? 1 : item.jmlNilaiBL"
            :variant="incompleteBarVariant(item)"
            :striped="stripedBar(item)"
            :show-value="valueNotExists(item) === false"
          />
        </b-progress>
      </template>
    </c-table>

    <DashboardDosenKeterangan />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { parseStatusValue } from '@/helpers'
import {
  Table as CTable, TableMixin,
  FailedIcon, SuccessIcon,
  TitleIconButton
} from '@/components'
// modules
import DashboardDosenKeterangan from '@/modules/dashboard/dosen/Keterangan'

export default {
  name: 'DashboardPengelolaanNilai',
  mixins: [TableMixin],
  components: {
    DashboardDosenKeterangan,
    CTable,
    TitleIconButton
  },
  data () {
    return {
      noprogress: 0,
      inprogress: 0,
      complete: 0,
      badgeLoading: false,
      fields: [
        { key: 'mkkurNamaResmi', label: 'Nama Matakuliah', sortable: true },
        { key: 'klsNama', label: 'Nama Kelas', sortable: true },
        { key: 'dsnkIsBolehInputNilaiOnline', label: 'Status Input Nilai' },
        { key: 'jmlNilaiLengkap', label: 'Jumlah Nilai Mahasiswa (Lengkap)' },
        { key: 'jmlNilaiBL', label: 'Jumlah Nilai Mahasiswa (BL)' },
        { key: 'percentage', label: 'Nilai Lengkap' }
      ]
    }
  },
  computed: {
    ...mapState('dashboard', ['nilai', 'loadingNilai']),
    ...mapGetters('dashboard', ['getSummaryNilai'])
  },
  async beforeMount () {
    this.badgeLoading = true
    await this.getNilai()
    this.cardSummary()
    this.badgeLoading = false
  },
  methods: {
    ...mapActions('dashboard', ['getNilai']),
    valueNotExists (item) {
      const { lengkap, belumLengkap } = parseStatusValue(item)
      return lengkap === 0 && belumLengkap === 0
    },
    total (data) {
      const { lengkap, belumLengkap } = parseStatusValue(data)
      return parseInt(lengkap + belumLengkap)
    },
    bagdeGradeStatus (state) {
      const states = {
        1: 'outline-success',
        0: 'outline-danger'
      }

      return states[state]
    },
    bagdeGradeIcon (state) {
      const states = [FailedIcon, SuccessIcon]

      return states[state]
    },
    incompleteBarVariant (data) {
      const { lengkap, belumLengkap } = parseStatusValue(data)

      return (lengkap === 0 && belumLengkap === 0) ? 'dark' : (lengkap === 0) ? 'warning' : 'info'
    },
    completeBarVariant (data) {
      const { lengkap, belumLengkap } = parseStatusValue(data)

      return (lengkap === 0 && belumLengkap === 0) ? 'dark' : (lengkap === this.total(data)) ? 'success' : 'primary'
    },
    animateBar (data) {
      const { lengkap, belumLengkap } = parseStatusValue(data)

      return Boolean((lengkap > 0 && belumLengkap > 0) || ((lengkap < (lengkap + belumLengkap)) && belumLengkap > 0))
    },
    stripedBar (data) {
      const { lengkap, belumLengkap } = parseStatusValue(data)

      return Boolean((lengkap > 0 && belumLengkap > 0))
    },
    isBolehInputCaption ({ dsnkIsBolehInputNilaiOnline }) {
      return parseInt(dsnkIsBolehInputNilaiOnline) === 1 ? 'Boleh' : 'Tidak Ada Akses'
    },
    cardSummary () {
      const summary = this.getSummaryNilai

      const reduceNoProgress = item => item.lengkap === 0
      const reduceInProgress = item => item.lengkap > 0 && item.belumLengkap > 0
      const reduceComplete = item => item.lengkap === (item.lengkap + item.belumLengkap)

      this.noprogress = summary.reduce((total, item) => total + (reduceNoProgress(item) ? 1 : 0), 0)
      this.inprogress = summary.reduce((total, item) => total + (reduceInProgress(item) ? 1 : 0), 0)
      this.complete = summary.reduce((total, item) => total + (reduceComplete(item) ? 1 : 0), 0)
      this.badgeLoading = false
    }
  }
}
</script>

<style scoped lang="scss">
.bg-noprogress {
  background: rgb(255,181,7);
  background: -moz-linear-gradient(131deg, rgba(255,181,7,1) 50%, rgba(255,218,131,1) 100%);
  background: -webkit-linear-gradient(131deg, rgba(255,181,7,1) 50%, rgba(255,218,131,1) 100%);
  background: linear-gradient(131deg, rgba(255,181,7,1) 50%, rgba(255,218,131,1) 100%);
}
.bg-inprogress {
  background: rgb(13,110,253);
  background: -moz-linear-gradient(131deg, rgba(13,110,253,1) 50%, rgba(115,171,254,1) 100%);
  background: -webkit-linear-gradient(131deg, rgba(13,110,253,1) 50%, rgba(115,171,254,1) 100%);
  background: linear-gradient(131deg, rgba(13,110,253,1) 50%, rgba(115,171,254,1) 100%);
}
.bg-complete {
  background: rgb(77,189,116);
  background: -moz-linear-gradient(131deg, rgba(77,189,116,1) 50%, rgba(133,210,161,1) 100%);
  background: -webkit-linear-gradient(131deg, rgba(77,189,116,1) 50%, rgba(133,210,161,1) 100%);
  background: linear-gradient(131deg, rgba(77,189,116,1) 50%, rgba(133,210,161,1) 100%);
}
</style>
