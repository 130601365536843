<template>
  <div class="row d-flex justify-content-between mb-1">
    <div class="col-lg-4 col-md-6 col-sm-8">
      <p class="lead">Keterangan Status Input Nilai</p>
      <dl class="row">
        <dt class="col-4">Boleh Input</dt>
        <dd class="col-8">Dosen diperbolehkan untuk input nilai.</dd>
        <dt class="col-4">Tidak Ada Akses</dt>
        <dd class="col-8">Dosen tidak mendapatkan akses untuk input nilai, silahkan hubungi admin SIA Fakultas.</dd>
      </dl>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-8">
      <p class="lead">Keterangan Bar</p>
      <dl class="row">
        <dt class="col-8">Terpenuhi</dt>
        <dd class="col-4">
          <b-progress class="w-50" :max="1" :value="1" variant="success" />
        </dd>
        <dt class="col-8">Tidak Terpenuhi</dt>
        <dd class="col-4">
          <b-progress class="w-50" :max="1" :value="1" variant="warning" />
        </dd>
        <dt class="col-8">Lengkap</dt>
        <dd class="col-4">
          <b-progress class="w-50" :max="1" :value="1" variant="primary" striped animated />
        </dd>
        <dt class="col-8">Belum Lengkap</dt>
        <dd class="col-4">
          <b-progress class="w-50" :max="1" :value="1" variant="info" striped animated />
        </dd>
        <dt class="col-8">Tidak Ada KRS Mahasiswa / Tidak Mengajar</dt>
        <dd class="col-4">
          <b-progress class="w-50" :max="1" :value="1" variant="dark" />
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardDosenKeterangan'
}
</script>
