<template>
  <div class="app">
    <AppHeader />
    <div class="app-body">
      <AppSidebar />
      <main class="main">
        <div class="container-fluid pt-3">
          <router-view />
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import AppHeader from '@/components/Header'
import AppSidebar from '@/components/Sidebar'

export default {
  name: 'Home',
  components: {
    AppHeader,
    AppSidebar
  }
}
</script>
