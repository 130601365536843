<template>
  <div>
    <b-card no-body class="shadow-sm">
      <template v-slot:header>
        <header>
          Dashboard Dosen - Selamat Datang
          <strong>{{ nama }}</strong>
        </header>
      </template>
    </b-card>

    <b-card header="Pengelolaan Nilai" class="shadow-sm">
      <DashboardPengelolaanNilai />
    </b-card>
    <b-card header="Bimbingan Akademik (Dosen PA)" class="shadow-sm">
      <DashboardBimbinganAkademik />
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { lectureNameConcat } from '@/helpers'
// module
import DashboardPengelolaanNilai from '@/modules/dashboard/dosen/PengelolaanNilai'
import DashboardBimbinganAkademik from '@/modules/dashboard/dosen/BimbinganAkademik'

export default {
  name: 'DashboardDosen',
  components: {
    DashboardBimbinganAkademik,
    DashboardPengelolaanNilai
  },
  computed: {
    ...mapGetters({
      userProfile: 'users/getUserProfile'
    }),
    nama () {
      return lectureNameConcat(
        this.userProfile.pegNama,
        this.userProfile.pegGelarBelakang,
        this.userProfile.pegGelarDepan
      )
    }
  }
}
</script>
