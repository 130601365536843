<template>
  <div>
    <b-card no-body>
      <template #header>
        <p class="h5 font-weight-bold">
          Monitoring Input Nilai Mahasiswa Dosen Seluruh Fakultas
        </p>
      </template>
    </b-card>
    <b-row lg="6" md="8" sm="12">
      <b-col>
        <b-card header="Fakultas">
          <vue-apex-charts type="bar"
                           :series="facultyChartSeries"
                           :options="facultyChartOptions" />
        </b-card>
      </b-col>
      <b-col>
        <b-card header-class="d-flex justify-content-between">
          <template #header>
            <p class="align-self-center">Program Studi</p>
            <div class="col-4">
              <b-form-select
                v-model="selectedFaculty"
                :options="selectFacultyOptions"
                @change="changeMajors"
              />
            </div>
          </template>

          <vue-apex-charts type="bar"
                           v-if="selectedFacultyData.length > 0"
                           :series="selectedFacultyData"
                           :options="majorsChartOptions" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'DashboardInternal',
  components: {
    VueApexCharts
  },
  data () {
    return {
      loading: false,
      selectedFaculty: null,
      selectedFacultyData: [],
      defaultChartOptions: {
        chart: {
          type: 'bar',
          stacked: true,
          stackType: '100%'
        },
        colors: [
          '#fd7e14',
          '#007bff',
          '#4dbd74'
        ],
        noData: {
          text: 'Mohon tunggu, proses data...'
        },
        legend: {
          position: 'top'
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters('dashboard', {
      gradeEntriesStatus: 'getGradeEntriesStatus',
      facultyChartData: 'getChartForFaculties',
      facultyNames: 'getFaculties',
      majorNames: 'getMajors'
    }),
    facultyChartSeries () {
      return this.facultyChartData
    },
    selectFacultyOptions () {
      return [{ value: null, text: '-- Pilih Fakultas --' }]
        .concat(this.facultyNames.map(item => ({ value: item, text: item })))
    },
    facultyChartOptions () {
      return {
        ...this.defaultChartOptions,
        xaxis: {
          categories: this.facultyNames
        },
        title: {
          text: 'Monitoring di Seluruh Fakultas',
          align: 'center'
        }
      }
    },
    majorsChartOptions () {
      const majorName = this.selectedFaculty === 'Pascasarjana'
        ? 'Program Pascasarjana' : 'Fakultas ' + this.selectedFaculty

      return {
        ...this.defaultChartOptions,
        xaxis: {
          categories: this.majorNames
        },
        title: {
          text: 'Monitoring di ' + majorName,
          align: 'center'
        }
      }
    }
  },
  beforeMount () {
    this.actionAllLectureGradeEntriesStatus()
  },
  methods: {
    ...mapActions('dashboard', [
      'actionAllLectureGradeEntriesStatus',
      'getFacultyWithMajors'
    ]),
    async changeMajors (val) {
      if (val !== null) {
        this.selectedFacultyData = await this.getFacultyWithMajors(val)
      }
    }
  }
}
</script>
