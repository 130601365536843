<template>
  <c-table
    :table-data="bimbinganAkademik"
    :fields="captions"
    primary-key="prodi"
    :loading="loading"
  />
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { Table as CTable, TableMixin } from '@/components'

export default {
  name: 'DashboardBimbinganAkademik',
  mixins: [TableMixin],
  components: {
    CTable
  },
  data () {
    return {
      fields: [
        { key: 'prodi', label: 'Program Studi' },
        { key: 'jmlDisetujui', label: 'Jumlah SKS Mahasiswa Disetujui' },
        { key: 'jmlTdkDisetujui', label: 'Jumlah SKS Mahasiswa Tidak Disetujui' },
        { key: 'jmlBatal', label: 'Jumlah SKS Mahasiswa Batal' },
        { key: 'jmlMahasiswa', label: 'Jumlah Mahasiswa Keseluruhan' }
      ]
    }
  },
  computed: mapState('dashboard', {
    loading: 'loadingBimbinganAkademik',
    bimbinganAkademik: 'bimbinganAkademik'
  }),
  async beforeMount () {
    await this.getBimbinganAkademik()
  },
  methods: {
    ...mapActions('dashboard', ['getBimbinganAkademik'])
  }
}
</script>
